import { request } from '@/utils/request-utils.js';



// ================================================================================================
// 资金 V1
// ================================================================================================

// 获取 账户详情
export function apiV1GetShopAccountObject(data) { return request({ url: `/api/admin/v1/finance/shop-accounts/${data.shop_id}`, method: 'get', params: data, token: true }) }
// 获取 账户详情日志 分页
export function apiV1GetShopAccountLogPage(data) { return request({url: `/api/admin/v1/finance/shop-accounts/${data.shop_id}/logs`, method: 'get', params: data, token: true}) }


// 操作 账户调整
export function apiV1ShopAccountActionAdjust(data) { return request({ url: `/api/admin/v1/finance/shop-accounts/${data.shop_id}/actions/adjust`, method: 'post', data, token: true }) }
