<template>
  <el-card :body-style="{padding:'0px'}">
    <el-form class="ep-search-panel" ref="searchForm" label-position="top">

      <!-- 普通查询部分 START -->
      <el-row class="m-common-field" :gutter="20">
        <!-- <el-col :span="6">
          <el-form-item class="m-common">
            <template #label><span class="ep-search-panel-field-label">订单查询</span></template>
            <el-input placeholder="请输入查询内容" v-model="searchContent1" class="ep-search-form-input-with-select">
              <template #prepend>
                <el-select v-model="searchKey1" placeholder="请选择">
                  <el-option key="order_sn" label="订单编号" value="order_sn" />
                  <el-option key="main_order_sn" label="主订单号" value="main_order_sn" />
                  <el-option key="waybill_number" label="运单号" value="waybill_number" />
                </el-select>
              </template>
            </el-input>
          </el-form-item>
        </el-col> -->
        
        <el-col :span="6">
          <el-form-item>
            <template #label><span class="ep-search-panel-field-label">日期时间</span></template>
            <el-date-picker v-model="createdTime" type="datetimerange"
              :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right">
            </el-date-picker>
          </el-form-item>
        </el-col>

        <!-- <el-col :span="6">
          <el-form-item>
            <template #label><span class="ep-search-panel-field-label">订单状态</span></template>
            <el-select v-model="order_state" placeholder="订单状态" style="width:100%">
              <el-option label="全部" value="__ALL__">全部</el-option>
              <el-option label="未接单" value="0">未接单</el-option>
              <el-option label="申报中" value="2">申报中</el-option>
              <el-option label="已取消" value="5">已取消</el-option>
            </el-select>
          </el-form-item>
        </el-col> -->

        <!-- <el-col :span="6">
          <el-form-item>
            <template #label><span class="ep-search-panel-field-label">订单类型</span></template>
            <el-select v-model="oms_order_type" placeholder="订单类型" style="width:100%">
              <el-option label="全部" value="__ALL__">全部</el-option>
              <el-option label="普通订单" value="0">普通订单</el-option>
              <el-option label="分销订单" value="10">分销订单</el-option>
              <el-option label="供应订单" value="20">供应订单</el-option>
            </el-select>
          </el-form-item>
        </el-col> -->

      </el-row>
      <!-- 普通查询部分 END -->

      <!-- 高级查询部分 START -->
      <div class="m-advance-filed" v-show="this.advanceToggle">
        <!-- <div class="ep-search-form-item-row">
          <div class="ep-search-form-item-col1">
            <el-form-item label="商户编码：">
              <el-input v-model="searchModel.merchant_code" />
            </el-form-item>
          </div>
          <div class="ep-search-form-item-col1">
            <el-form-item label="商户编码：">
              <el-input v-model="searchModel.merchant_code" />
            </el-form-item>
          </div>
          <div class="ep-search-form-item-col1">
            <el-form-item label="商户编码：">
              <el-input v-model="searchModel.merchant_code" />
            </el-form-item>
          </div>
        </div> -->
      </div>
      <!-- 高级查询部分 END -->

      <!-- 功能按键组 START -->
      <div>
        <el-button type="primary" @click="onSearchClick">查询</el-button>
        <el-button type="primary" plain @click="onAdvanceToggle">高级查询</el-button>
        <el-button type="primary" plain @click="onResetClick">重置</el-button>

        <span class="ep-form-button-separation" />

        <el-button type="primary" @click="onExportClick">导出</el-button>

        <!-- <el-dropdown split-button type="primary" @click="onSearchClick">
          <span>查询</span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="onAdvanceToggle">高级查询</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown> -->
      </div>
      <!-- 功能按键组 END -->
  
    </el-form>
  </el-card>
</template>

<script>
import { ArrowDown } from '@element-plus/icons-vue'

export default {
  name: "order-search-panel",
  components: {
    ArrowDown,
  },
  props: [],
  emits: ['search', 'reset', 'export'],
  data() {
    return {
      // 高级查询切换标记
      advanceToggle: false,
      // 查询字段 - 订单搜索
      searchKey1: 'order_sn',
      searchContent1: '',
      // 查询字段 - 订单状态
      order_state: '__ALL__',
      // 查询字段 - 下单时间
      createdTime: [],
      // 查询字段 - 订单类型
      oms_order_type: '__ALL__',
      // 时间选择快捷方式
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 点击触发 - 查询
    onSearchClick() {
      var searchData = this.getSearchFilter()
      this.$emit('search', searchData)
    },
    // 点击触发 - 高级查询
    onAdvanceToggle() {
      this.advanceToggle = !this.advanceToggle
    },
    // 点击触发 - 重置
    onResetClick() {
      this.searchContent1 = ''
      this.createdTime = []
      this.order_state = '__ALL__'
      this.oms_order_type = '__ALL__'

      this.$emit('reset', {})
    },

    onExportClick() {
      var searchData = this.getSearchFilter()
      this.$emit('export', searchData)
    },
    
    // ================================================================================================
    // 获取查询filter
    getSearchFilter() {
      let searchFilter = {}
      if (this.searchKey1.trim() != '' && this.searchContent1.trim() != '') {
        searchFilter = {[this.searchKey1]: this.searchContent1}
      }
      if (this.order_state != '__ALL__') {
        searchFilter.order_state = this.order_state
      }
      if (this.createdTime != null && this.createdTime.length > 0) {
        var start_created = parseInt(this.createdTime[0].getTime() / 1000)
        var end_created = parseInt(this.createdTime[1].getTime() / 1000)

        searchFilter.change_time_start = start_created
        searchFilter.change_time_end = end_created
      }
      if (this.oms_order_type != '__ALL__') {
        searchFilter.oms_order_type = this.oms_order_type
      }
      
      return searchFilter
    },
    
    
  },
  mounted() {
    // 初始化时间 最近24小时
    // const createdTimeStart = new Date();
    // createdTimeStart.setTime(createdTimeStart.getTime() - 3600 * 24 * 365 * 1000);
    // this.createdTime = [createdTimeStart, new Date()]
  }
};
</script>

<style scoped>
</style>
