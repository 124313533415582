<template>
  <el-container class="ep-page-container">
    <el-header class="ep-page-header">
      <div class="ep-page-header-breadcrumb">
        <el-breadcrumb><el-breadcrumb-item>资金</el-breadcrumb-item><el-breadcrumb-item>账户管理</el-breadcrumb-item><el-breadcrumb-item>账户信息</el-breadcrumb-item></el-breadcrumb>
      </div>
      <div class="ep-page-header-filter"></div>
      <div class="ep-page-header-toolbar">
        <!-- <el-button type="primary" @click="onAdjustClick"><span>资金管理</span></el-button> -->
      </div>
    </el-header>
    <!-- 其他窗体 START -->
    <!-- <AccountAdjustView ref="refAccountAdjustView" @submit="onEditSubmit" /> -->

    
    <!-- 其他窗体 END -->
    <el-main class="ep-page-main">
      <!-- 账户概览 START -->
      <AccountInfoView ref="refAccountInfoView" @search="onSearched" @reset="onSearchReset"/>
      <!-- 账户概览 END -->

      <!-- 查询面板 START -->
      <LogSearchPanel ref="searchView" @search="onSearched" @reset="onSearchReset" @export="onExport" style="margin-top: 10px;"/>
      <!-- 查询面板 END -->

      <!-- 数据表格 START -->
      <div class="el-card" style="margin:10px 0 80px 0;">
        <!-- 数据表格-过滤标签栏 START -->
        <el-tabs type="card" v-model="dataTableFilter" @tab-change="onDataTableTabChange">
          <el-tab-pane label="全部" name="__ALL__"></el-tab-pane>
          <!-- <el-tab-pane label="未审核" name="0"></el-tab-pane> -->
          <!-- <el-tab-pane label="已审核" name="1"></el-tab-pane> -->
        </el-tabs>
        <!-- 数据表格-过滤标签栏 END -->

        <!-- 数据表格 START -->
        <!-- :height="esFixedTableHeight"  -->
        <div class="ep-table-panel-wrap">
          <el-table ref="epDataTable" fit stripe highlight-current-row size="small" v-loading="epIsLoading"
            :data="epDataList" :header-cell-style="{background:'#eef1f6',color:'#606266'}"
            @selection-change="onTableSelectionChange">
            
            <el-table-column type="selection" width="50" />

            <el-table-column prop="log_id" label="#" width="80" />

            <el-table-column label="时间" width="120">
              <template #default="scope">
                <span v-html="$$utils.toDataTimeHtml(scope.row.change_time)"></span>
              </template>
            </el-table-column>

            <el-table-column label="业务类型" min-width="150">
              <template #default="scope">
                <template v-if="scope.row.biz_type == 'shopAccount.adjust'">账户金额调整</template>
                <!-- <template v-else-if="scope.row.contractStatus == 'SIGNING_CONTRACT'"><el-tag type="warning" effect="dark">签约中</el-tag></template> -->
                <template v-else>其它</template>
              </template>
            </el-table-column>

            <el-table-column label="可用余额变动（元）" align="right" min-width="150">
              <template #default="scope">
                <template v-if="scope.row.ab_change_type == 1"><span class="m-change-type_1">增加 ￥ {{ scope.row.ab_change_value }}</span></template>
                <template v-else-if="scope.row.ab_change_type == 2"><span class="m-change-type_2">减少 ￥ {{ scope.row.ab_change_value }}</span></template>
                <template v-else>-</template>
              </template>
            </el-table-column>

            <el-table-column label="可用余额（元）" align="right" min-width="100">
              <template #default="scope"><span>￥ {{ scope.row.ab_new_value }}</span></template>
            </el-table-column>

            <el-table-column label="冻结余额变动（元）" align="right" min-width="150">
              <template #default="scope">
                <template v-if="scope.row.fb_change_type == 1"><span class="m-change-type_1">增加 ￥ {{ scope.row.fb_change_value }}</span></template>
                <template v-else-if="scope.row.fb_change_type == 2"><span class="m-change-type_2">减少 ￥ {{ scope.row.fb_change_value }}</span></template>
                <template v-else>-</template>
              </template>
            </el-table-column>
            <el-table-column label="冻结余额（元）" align="right" min-width="100">
              <template #default="scope"><span>￥ {{ scope.row.fb_new_value }}</span></template>
            </el-table-column>

            <el-table-column prop="remark" label="备注" min-width="200" />

            <!-- <el-table-column label="操作" min-width="150" fixed="right"> -->
              <!-- <template #default="scope"> -->
                <!-- <span class="ep-action-button primary" @click="onDetailClick(scope.row)">账户详情</span> -->
                <!-- <span class="ep-action-line"/> -->
                <!-- <span class="ep-action-button primary" @click="/onUpdateClick(scope.row)">编辑</span> -->
                <!-- <span class="ep-action-line"/> -->
                <!-- <span class="ep-action-button danger" @click="onDeleteClick(scope.row)">删除</span> -->
              <!-- </template> -->
            <!-- </el-table-column> -->
          </el-table>
        </div>
        <!-- 数据表格 END -->
      </div>
      <!-- 数据表格面板 END -->

      <!-- 数据页面固定footer START -->
      <div class="ep-float-footer">
        <div class="ep-float-footer-toolbar">
          <el-button plain class="ep-button-select-all" @click="$refs.epDataTable.toggleAllSelection()">
            <span class="ep-button-select-all-text1">已选中 <span class="ep-button-select-all-text2">{{ epMultipleSelection.length }}</span> / <span class="ep-button-select-all-text2">{{ epDataList.length }}</span> 项</span>
          </el-button>
          <!-- <el-button type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onCheckClick">审核</el-button>
          <el-button type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onCancelClick">作废</el-button>
          <el-button type="primary" :disabled="getMultipleSelectionCount() == 0" @click="onUnancelClick">恢复</el-button> -->
        </div>
        <div class="ep-float-footer-pager">
          <ep-pagination :ep-page="this.epPage" :ep-total="this.epTotal" :ep-page-size="this.epPageSize" @pagination-change="onPaginationChange"/>
        </div>
      </div>
      <!-- 数据页面固定footer END -->

    </el-main>
    
  </el-container>
</template>

<script>
import DataTableMixin from '@/mixin/DataTableMixin.js'
import { Edit, ArrowDown, MoreFilled } from '@element-plus/icons-vue'
// import ShopEditView from '@/views/v1/merchant/shop/edit/ShopEditView'
// import ShopSearchPanel from '@/views/v1/merchant/shop/list/ShopSearchPanel'
import SourceCodeTag from '@/components/v1/jxgocs/tags/SourceCodeTag'
// import AccountAdjustView from '@/views/v1/finance/account/list/AccountAdjustView'
import AccountInfoView from '@/views/v1/finance/account/list/AccountInfoView'
import LogSearchPanel from '@/views/v1/finance/account/list/LogSearchPanel'
// import { apiV1GetShopPage, apiV1DeleteShop } from '@/api/merchant.js'
import { apiV1GetShopAccountLogPage } from '@/api/finance.js'
import AuthUtils from '@/utils/auth-utils.js'
import GlobalConfig from '@/config/index.js'


export default {
  mixins: [ DataTableMixin ],
  name: "account-list-page",
  components: {
    Edit, // Edit, ArrowDown,
    // ShopEditView,
    // ShopSearchPanel,
    SourceCodeTag,
    // AccountAdjustView,
    AccountInfoView,
    LogSearchPanel,
  },
  props: [],
  data() {
    return {
      shop_id: '',
      // current_shop_id: '',
      // current_shop_id: '', //
      //
      dataTableFilter: '__ALL__',
    }
  },
  computed: {},
  watch: {},
  methods: {
    getFilter() {
      let data = { shop_id: AuthUtils.getCurrentShopId() }
      // if (AuthUtils.getCurrentShopId()) {
      //   data.shop_id = AuthUtils.getCurrentShopId()
      // }
      // if (this.dataTableFilter != '' && this.dataTableFilter != '__ALL__') {
      //   data.admin_check_state = this.dataTableFilter
      // }
      return data
    },
    getPage({reload = false} = {}) {
      let paginationData = this.getPagination(reload)
      let filterData = this.getFilter()
      let searchData = this.$refs.searchView.getSearchFilter() 
      let queryData = Object.assign(paginationData, filterData, searchData)

      this.epIsLoading = true

      apiV1GetShopAccountLogPage(queryData).then((res) => {
        this.epDataList = res.data.data
        this.setPaginationTotal(res.data.pagination.total)
        this.epIsLoading = false
      })
      
    },


    // ===================================================================================================


    // 回调触发 - 查询
    onSearched(data) {
      this.getPage({reload: true})
    },
    // 回调触发 - 查询重置
    onSearchReset() {
      this.getPage({reload: true})
    },
    // Change触发 - 数据表Tab
    onDataTableTabChange(name) {
      this.getPage({reload: true})
    },

    // 编辑提交
    onEditSubmit(result) {
      this.getPage()
      this.$message.success("操作成功")
    },
    // 点击触发 - 添加
    onAdjustClick(id) {
      this.$refs.refAccountAdjustView.showView()
    },
    // 点击触发 - 查看店铺详情
    onDetailClick(rowObj) {
      let newUrl = this.$router.resolve({ path: `/v1/finance/account/${id}/list` })
      window.open(newUrl.href, '_blank')
      // this.$refs.refAccountDetailDialog.showView({ editMode: "update", id: rowObj.shop_id })
    },
    // 点击触发 - 查看店铺详情
    onMerchantDetailClick(rowObj) {
      let newUrl = this.$router.resolve({ name: 'MerchantsDetailPage', params: { id: rowObj.merchant_id } })
      window.open(newUrl.href, '_blank')
    },
    // 点击触发 - 修改
    onUpdateClick(rowObj) {
      this.$refs.updateView.showView({ editMode: "update", id: rowObj.shop_id })
    },
    // 点击触发 - 删除
    onDeleteClick(rowObj) {
      // this.resolveDeleteConfirm().then(() => {
      //   apiV1DeleteShop({
      //     shop_id: rowObj.shop_id,
      //   }).then((res) => {
      //     this.getPage()
      //     this.$message.success("操作成功")
      //   })
      // }).catch(()=>{})
    },
    onExport() {
      let filterData = this.getFilter()
      let searchData = this.$refs.searchView.getSearchFilter()
      let queryData = Object.assign(filterData, searchData)

      let params='';for(let key in queryData){if(queryData.hasOwnProperty(key)){params+=`${encodeURIComponent(key)}=${encodeURIComponent(queryData[key])}&`}};params=params.slice(0,-1)
      // console.log(queryData); console.log(mode);  return

      var url = `${GlobalConfig.host}api/admin/v1/finance/shop-accounts/${this.shop_id}/actions/export?` + params
      
      window.open(url) 
    },

    // ===================================================================================================


  },
  mounted() {
    this.shop_id = this.$route.params.id
    this.$nextTick(() => {
      this.getPage()
      // this.currentMainTabIndex = 'procedure-config'
    })
  }
}
</script>

<style scoped>
.m-change-type_1 { color: #F56C6C; }
.m-change-type_2 { color: #67C23A; }
.m-new-value-1 { text-align: right; }
</style>
