<template>
  <el-card :body-style="{padding:'10px 20px'}">
    <template #default>
      <div class="card-header">
        <el-space :size="20">
          <span>账户概览：</span>
          <span><SourceCodeTag :value="source_code" style="margin-right:4px;" />{{ shop_name }}</span>
          <el-button type="primary" text @click="initView"><el-icon><Refresh/></el-icon><span>刷新</span></el-button>
        </el-space>
      </div>
      <div class="m-info-main">
        <el-skeleton :rows="1" :loading="epIsLoading" animated>
          <el-row>
            <el-col :span="6">
              <el-statistic :value="available_balance" :precision="2">
                <template #prefix>￥</template>
                <template #title><el-text type="success">可用余额</el-text></template>
              </el-statistic>
            </el-col>
            <el-col :span="6">
              <el-statistic :value="frozen_balance" :precision="2">
                <template #prefix>￥</template>
                <template #title><el-text type="info">冻结余额</el-text></template>
              </el-statistic>
            </el-col>
          </el-row>
        </el-skeleton>
      </div>
    </template>
  </el-card>
</template>

<script>
// import DataTableMixin from '@/mixin/DataTableMixin.js'
import { Refresh, Check, Close } from '@element-plus/icons-vue'
import SourceCodeTag from '@/components/v1/jxgocs/tags/SourceCodeTag'
import { apiV1GetShopAccountObject } from '@/api/finance.js'
import AuthUtils from '@/utils/auth-utils.js'

export default {
  // mixins: [ DataTableMixin ],
  name: 'account-info-view',
  components: {
    Refresh, Check, Close,
    SourceCodeTag,
  },
  props: [],
  data() {
    return {
      epIsLoading: false,
      shop_id: '',
      //
      shop_name: '',
      source_code: '',
      available_balance: 0.0,
      frozen_balance: 0.0,
    }
  },
  computed: {
  },
  methods: {
    // 初始化view
    initView() {
      this.getShopAccountObject()
    },

    getShopAccountObject() {
      this.shop_name = ''; this.source_code = ''; this.available_balance = 0.0; this.frozen_balance = 0.0; 
      this.epIsLoading = true

      apiV1GetShopAccountObject({
        shop_id: this.shop_id,
      }).then((res) => {
        if (res.data.code != 0) {
          var message = res.data.message ? res.data.message : '获取信息失败'
          this.$message.error(message)
        } else {
          this.shop_name = res.data.data.shop_name
          this.source_code = res.data.data.source_code
          this.available_balance = parseFloat(res.data.data.available_balance)
          this.frozen_balance = parseFloat(res.data.data.frozen_balance)

          this.epIsLoading = false
        }
        
      })
    },

    // ===================================================================================================


  },
  mounted() {
    this.shop_id = AuthUtils.getCurrentShopId()
    this.$nextTick(() => {
      this.getShopAccountObject()
    })
  }
};
</script>

<style scoped>
.m-info-main { border-top: 1px solid var(--el-border-color-light); margin-top: 10px; padding: 10px 0; }
</style>
